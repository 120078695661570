
import slider1 from "../Images/slider1.png"
import slider2 from "../Images/slider2.png"
import slider3 from "../Images/slider3.png"
import slider4 from "../Images/slider4.png"
import slider5 from "../Images/slider5.png"

export const carouselData = [
    {
      id: 1,
      heading: 'My Smiles',
      subHeading: 'Personalized Smile Content',
      imgSrc: slider1
    },
    {
      id: 2,
      heading: 'Smile Powered',
      subHeading: 'Feel the Power of Your Smile',
      imgSrc: slider2
    },
    {
      id: 3,
      heading: 'Guided Smiling',
      subHeading: 'Feel the Calm of Your Smile',
      imgSrc: slider3
    },
    {
      id: 4,
      heading: 'Smile Stats',
      subHeading: 'Set Goals and Track Progress',
      imgSrc: slider4
    },
    {
      id: 5,
      heading: 'Smilestones',
      subHeading: 'Achieve Levels and Bonus Content',
      imgSrc: slider5
    },
  ]